import { useAuth } from 'common/helper/hooks/useAuth';
import React, { Navigate, Outlet } from 'react-router-dom';

export const ProProtectedLayout = () => {
  const { user } = useAuth();

  if (!user || user?.mode !== 'pro') {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
