import React, { lazy } from 'react';

import { Routes, Route } from 'react-router-dom';

import MainLayout from 'layout/main.layout';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from 'common/helper/hooks/useAuth';
import { ProtectedLayout } from 'layout/protected/protected.latout';
import { ProProtectedLayout } from 'layout/protected/pro-protected.latout';
import FirstStartTipModal from 'common/modal/start-tip/support-start.modal';
import { useAppDispatch, useAppSelector } from 'store/interface/store.interface';
import { setFirstStartTipReducer, setStartTipReducer } from 'store/slices/tips/reducers/set-start-tip.reducer';
import EndTrialModal from 'common/modal/payments/end-trial/end-trial.modal';
import StartTipClickModal from 'common/modal/start-tip/support-click.modal';
import FeedbackFrameModal from 'common/modal/feedback-frame/feedback-frame.modal';

const PaymentsPage = lazy(() => import('./pages/payments/payments.page'));
const Pricing = lazy(() => import('./pages/pricing/pricing.page'));
const About = lazy(() => import('./pages/about/about.page'));
const RecoveryPage = lazy(() => import('./pages/recovery/recovery.page'));
const ActivatePage = lazy(() => import('pages/activate/activate.page'));
const RegistrationPage = lazy(() => import('pages/registration/registration.page'));
const LoginPage = lazy(() => import('pages/login/login.page'));
const ConnectionAcceptedCandidatesList = lazy(() => import('pages/connection-list/connection-accepted-list.page'));
const ValidatePage = lazy(() => import('pages/home/validate.page'));
const Vacancies = lazy(() => import('pages/vacancies/vacancies.page'));
const AllVacancies = lazy(() => import('pages/all-vacancies/all-vacancies.page'));
const ManageUsersPage = lazy(() => import('pages/manage-users/manage-users.page'));

const App = () => {
  const dispatch = useAppDispatch();
  const { startTip, firstStartTip } = useAppSelector((state) => state.tips);
  const { isEndTrialModalOpen } = useAppSelector((state) => state.payments);

  const handleFirstStartTip = () => {
    dispatch(setFirstStartTipReducer(!firstStartTip));
  };

  const handleStartTip = () => {
    dispatch(setStartTipReducer(!startTip));
  };

  return (
    <>
      {/*todo: на время убираем*/}
      {/*todo refactor - сделать без active; смотреть slice внутри компонента*/}
      {/*<EndTrialModal active={isEndTrialModalOpen}/>*/}
      <FeedbackFrameModal/>
      <FirstStartTipModal active={firstStartTip} setActive={handleFirstStartTip} />
      <StartTipClickModal active={startTip} setActive={handleStartTip} />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/activate" element={<ActivatePage />} />
            <Route path="/recovery" element={<RecoveryPage />} />
            <Route path="/*" element={<p>Not found</p>} />

            <Route path="/profile" element={<ProtectedLayout />}>
              <Route index element={<Vacancies />} />
              <Route path="candidates" element={<ValidatePage />} />
              {/*todo: на время убираем*/}
              {/*<Route path="payments" element={<PaymentsPage />} />*/}
              <Route path="" element={<ProProtectedLayout />}>
                <Route path="connectionAcceptedList" element={<ConnectionAcceptedCandidatesList />} />
                <Route path="vacancies/all" element={<AllVacancies />} />
                <Route path="manage-users" element={<ManageUsersPage />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
