import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteLinkedInUserToAccountGroup } from '@EZenkof/common-recruiter-library';

import { IUsersSlice } from '../users.slice';

export const deleteLinkedInUserReducer = createAsyncThunk<string, string>(
  'users/deleteLinkedInUser',
  async (id, { rejectWithValue }) => {
    try {
      await DeleteLinkedInUserToAccountGroup(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteLinkedInUserPending = (state: IUsersSlice) => {
  state.linkedIn.isLoading = true;
};
export const deleteLinkedInUserFullfiled = (state: IUsersSlice, action: PayloadAction<string>) => {
  state.linkedIn.users = state.linkedIn.users.filter((user) => user.id !== action.payload);
  state.linkedIn.count = state.linkedIn.count - 1;
  state.linkedIn.isLoading = false;
};
export const deleteLinkedInUserRejected = (state: IUsersSlice) => {
  state.linkedIn.isLoading = false;
};
