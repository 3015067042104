import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetVacancies,
  GetVacanciesCount,
  IRecruitingVacancy,
  IFilterRecruitingVacancyDto,
} from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';

interface IFilter {
  vacancies: IRecruitingVacancy[];
  count: number;
}

export const filterVacanciesReducer = createAsyncThunk<IFilter, { data: IFilterRecruitingVacancyDto }>(
  'vacancies/filterVacancies',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await GetVacancies(data);
      const count = await GetVacanciesCount({
        status: data.status,
      });

      return { vacancies: res || [], count: count || 0 };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterVacanciesReducerPending = (state: IVacanciesSlice) => {
  state.isLoading = true;
};
export const filterVacanciesReducerFullfiled = (state: IVacanciesSlice, action: PayloadAction<IFilter>) => {
  state.vacancies = action.payload.vacancies;
  state.count = action.payload.count;
  state.isLoading = false;
};
export const filterVacanciesReducerRejected = (state: IVacanciesSlice) => {
  state.isLoading = false;
};
