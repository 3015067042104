import { AxiosRequestHeaders } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import 'index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from 'App';
import { BrowserRouter } from 'react-router-dom';
import 'common/api/config';
import { instance } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

instance.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

instance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    toast.error(error?.response?.data?.message || error.message);

    if (error?.response?.data?.message === 'Unauthorized') window.location.href = '/login';
  },
);

instance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('canbanUser') || '{}');
    if (user && user.token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${user.token}`,
      } as AxiosRequestHeaders;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
