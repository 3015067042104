import { combineReducers, configureStore } from '@reduxjs/toolkit';

import ValidateTechnologySlice from 'pages/home/slice/vatidate.slice';
import ValidateTechnologyConfirmSlice from 'pages/home/components/table/validate-modal/slice/vatidate-confirm.slice';
import ConnectionAcceptedSlice from 'pages/connection-list/slices/connection-list.slice';

import authSlice from './slices/authSlice';
import UsersSlice from './slices/users/users.slice';
import VacanciesSlice from './slices/vacancies/vacancies.slice';
import ParsePlansSlice from './slices/plans/plans.slice';
import TipsSlice from './slices/tips/tips.slice';
import availableParseLimitSlice from './slices/available-parse-limit/available-parse-limit.slice';
import PaymentsSlice from './slices/payments/payments.slice';
import FeedbackFramesSlice from './slices/feedback-frames/feedback-frames.slice';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  users: UsersSlice.reducer,
  tips: TipsSlice.reducer,
  vacancies: VacanciesSlice.reducer,
  parsePlan: ParsePlansSlice.reducer,
  connectionAcceptedCandidates: ConnectionAcceptedSlice.reducer,
  ValidateTechnologySlice: ValidateTechnologySlice.reducer,
  validateTechnologyConfirmSlice: ValidateTechnologyConfirmSlice.reducer,
  availableParseLimitSlice: availableParseLimitSlice.reducer,
  payments: PaymentsSlice.reducer,
  feedbackFrames: FeedbackFramesSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
