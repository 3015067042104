import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GetVacancyById, IRecruitingVacancy } from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';

interface IGet {
  id: number
}

interface IGetResult {
  vacancy: IRecruitingVacancy | null;
}

export const getVacancyByIdReducer = createAsyncThunk<IGetResult, IGet>(
  'vacancies/getVacancyById',
  async ({id}, {rejectWithValue}) => {
    try {
      const result = await GetVacancyById(id);
      return {vacancy: result};
    }catch(error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getVacancyByIdReducerPending = (state: IVacanciesSlice) => {
  state.recentVacancy = null;
  state.isLoading = true;
};
export const getVacancyByIdReducerFullfiled = (state: IVacanciesSlice, action: PayloadAction<IGetResult>) => {
  state.vacancies = state.vacancies.map((vacancy) => {
    if (vacancy.id === action.payload.vacancy?.id)
      return { ...vacancy, ...action.payload.vacancy };
    return vacancy;
  });
  state.recentVacancy = action.payload.vacancy;
  state.isLoading = false;
};
export const getVacancyByIdReducerRejected = (state: IVacanciesSlice) => {
  state.recentVacancy = null;
  state.isLoading = false;
};
