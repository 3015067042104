import { GetPaymentsInfo } from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import { IPaymentsInfo, IPaymentsSlice } from '../payments.slice';

interface IGetResult extends IPaymentsInfo {}

export const getPaymentsInfoReducer = createAsyncThunk<IGetResult, {}>(
  'paymentsSlice/getPaymentsInfo',
  async ({}, {rejectWithValue}) => {
    try {
      return await GetPaymentsInfo();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getPaymentsInfoReducerPending = (state: IPaymentsSlice) => {
  state.isLoading = true;
};
export const getPaymentsInfoReducerFullfiled = (state: IPaymentsSlice, action: PayloadAction<IGetResult>) => {
  state.info = {...state.info, ...action.payload};
  state.isLoading = false;
};
export const getPaymentsInfoReducerRejected = (state: IPaymentsSlice) => {
  state.isLoading = false;
  
  toast.error('Get payments info error');
};

