import * as React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreSvg from 'common/svg/expand-more.svg';
import { useAuth } from 'common/helper/hooks/useAuth';
import LogoutSvg from 'common/svg/logout.svg';

import { useEffect, useState, useRef } from 'react';

import useClickOutside from 'common/helper/hooks/use-click-outside.hook';

import AccountSvg from 'common/svg/account.svg';
import MoneySvg from 'common/svg/money.svg';

import { useAppDispatch, useAppSelector } from '../../../store/interface/store.interface';
import { getAvailableParseLimitReducer }
  from '../../../store/slices/available-parse-limit/reducers/get-available-parse-limit.reducer';

import { formatNumberUtil } from '../../helper/utils/format-number.util';

import styles from './header.module.scss';

interface IPage {
  name: string;
  id: number;
  path: string;
  mode?: 'pro' | 'unauthorized';
  children?: {
    name: string;
    id: number;
    path: string;
  }[];
}

const pages: IPage[] = [
  {
    name: 'Vacancies',
    id: 1,
    path: '/profile',
  },
  {
    name: 'Сandidates',
    id: 4,
    path: '/profile/candidates',
  },
  {
    name: 'Candidate status',
    id: 2,
    path: '/profile/connectionAcceptedList',
    mode: 'pro',
  },
  {
    name: 'Analytics',
    id: 6,
    path: '/profile/vacancies/all',
    mode: 'pro',
  },
  {
    name: 'Manage users',
    id: 7,
    path: '/profile/manage-users',
    mode: 'pro',
  },
  {
    name: 'Обзор',
    id: 8,
    path: '/',
    mode: 'unauthorized',
  },
  {
    name: 'Тарифы',
    id: 9,
    path: '/pricing',
    mode: 'unauthorized',
  },
  {
    name: 'Аккаунт',
    id: 10,
    path: '/login',
    mode: 'unauthorized',
  },
];

const getPages = (currentPath: string, mode?: 'pro' | 'light') => {
  return pages
    .filter((p) => {
      if (currentPath.includes('profile')) {
        if (p.mode === 'unauthorized') return null;
        if (!mode) return null;
        if (mode === 'pro') return p;
        if (p.mode !== 'pro') return p;
        return null;
      } else {
        if (p.mode === 'unauthorized') return p;
        return null;
      }
    })
    .map((page) => (
      <li key={page.id}>
        <NavLink className={`${styles.headerItem}`} to={page.path}>
          <div className={styles.expand}>
            <p>{page.name}</p>
            {page.children && <ExpandMoreSvg />}
          </div>
          {!page.children && <span className={styles.border}></span>}
        </NavLink>
        {page.children && (
          <div className={styles.children}>
            {page.children.map((child) => (
              <NavLink to={child.path} key={child.id} className={styles.child}>
                <p>{child.name}</p>
              </NavLink>
            ))}
          </div>
        )}
        {currentPath === page.path && <span className={styles.active__border}></span>}
      </li>
    ));
};

export const Header = () => {
  const { logout, user } = useAuth();
  const location = useLocation();

  const [currentPath, setCurrentPath] = React.useState(location.pathname);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const { limit: availableParseLimit } = useAppSelector((state) => state.availableParseLimitSlice);

  const dispatch = useAppDispatch();

  const profileListStyle = currentPath.includes('profile') ? { justifyContent: 'center' } : {};

  const handlePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const getAvailableLimit = () =>
    availableParseLimit !== null
      ? formatNumberUtil(availableParseLimit)
      : 'unavailable, please try later';

  React.useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (user) dispatch(getAvailableParseLimitReducer({}));
  }, [user]);

  return (
    <>
      <header className={styles.container}>
        <Logo />
        <div className={styles.list} style={profileListStyle}>
          <ul className={styles.navigation}>{getPages(currentPath, user?.mode)}</ul>
        </div>
        {user && currentPath.includes('profile') && (
          <div className={styles.profile}>
            <p>Available parse limit: {getAvailableLimit()}</p>
            <button onClick={handlePopup}>
              <AccountSvg />
            </button>
          </div>
        )}
      </header>
      <Popup isPopupOpen={isPopupOpen} handlePopup={handlePopup} logout={logout} />
    </>
  );
};

export const Logo = () => {
  return (
    <NavLink to="/" className={`${styles.headerItem} ${styles.logo}`}>
      <p>Hyranse</p>
    </NavLink>
  );
};

interface IAccountPopupProps {
  isPopupOpen: boolean;
  handlePopup: () => void;

  logout: () => void;
}

const Popup = ({ isPopupOpen, handlePopup, logout }: IAccountPopupProps) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  useClickOutside(ref, handlePopup, isPopupOpen);

  const className = `${styles.popup} ${isPopupOpen ? styles.show : ''}`;

  const handleClosePopup = () => {
    logout();
    handlePopup();
  };
  
  const navigateToPayments = () => {
    navigate('/profile/payments');
    handlePopup();
  };

  return (
    <div ref={ref} className={className}>
      {/*todo: на время убираем*/}
      {/*<button*/}
      {/*  title="Payments"*/}
      {/*  className={styles.button}*/}
      {/*  onClick={navigateToPayments}*/}
      {/*>*/}
      {/*  <MoneySvg/>*/}
      {/*  <h3>Payments & Subscriptions</h3>*/}
      {/*</button>*/}
      <button
        title="Log Out"
        className={styles.button}
        onClick={handleClosePopup}
      >
        <LogoutSvg/>
        <h3>Log Out</h3>
      </button>
    </div>
  );
};

export default Header;
