import { useAuth } from 'common/helper/hooks/useAuth';
import React, { Navigate, Outlet } from 'react-router-dom';

import CollectStatisticLayer from './collect-statistic.layer';

export const ProtectedLayout = () => {
  const { user, logout } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (!user?.mode) {
    logout();
    return <Navigate to="/" />;
  }

  return <>
    <CollectStatisticLayer/>
    <Outlet />
  </>;
};
