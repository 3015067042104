import { initializeApp } from '@firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDIHmd9g2Nn-G_dsS7g7paZ7F3jYM-RTA8',
  authDomain: 'liquid-gear-298016.firebaseapp.com',
  projectId: 'liquid-gear-298016',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
