import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPaymentsDetails } from '@EZenkof/common-recruiter-library';

import {
  getIsTrialEndedReducer,
  getIsTrialEndedReducerFullfiled,
  getIsTrialEndedReducerPending, getIsTrialEndedReducerRejected,
} from './reducers/get-is-trial-ended.reducer';
import {
  getPaymentsInfoReducer,
  getPaymentsInfoReducerFullfiled,
  getPaymentsInfoReducerPending, getPaymentsInfoReducerRejected,
} from './reducers/get-payments-info.reducer';

export interface IPaymentsInfo {
  subscriptionEndDate?: number;
  nextPaymentDate?: number;
  trialEndDate?: number;
  items: string[];
  quantity: number[];
  prices: number[];
  tax: number;
  finalPrice: number;
  details: IPaymentsDetails [];
}

export interface IPaymentsSlice {
  isLoading: boolean;
  isEndTrialModalOpen: boolean;
  info: IPaymentsInfo;
}

const initialState: IPaymentsSlice = {
  isLoading: false,
  isEndTrialModalOpen: false,
  info: {
    items: [],
    quantity: [],
    prices: [],
    tax: 0,
    finalPrice: 0,
    details: [],
  },
};

const PaymentsSlice = createSlice({
  name: 'paymentsSlice',
  initialState: initialState,
  reducers: {
    setIsEndTrialModalOpenReducer(state, action: PayloadAction<{isOpen: boolean}>){
      state.isEndTrialModalOpen = action.payload.isOpen;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getIsTrialEndedReducer.pending, getIsTrialEndedReducerPending)
      .addCase(getIsTrialEndedReducer.fulfilled, getIsTrialEndedReducerFullfiled)
      .addCase(getIsTrialEndedReducer.rejected, getIsTrialEndedReducerRejected)

      .addCase(getPaymentsInfoReducer.pending, getPaymentsInfoReducerPending)
      .addCase(getPaymentsInfoReducer.fulfilled, getPaymentsInfoReducerFullfiled)
      .addCase(getPaymentsInfoReducer.rejected, getPaymentsInfoReducerRejected);
  },
});

export const {setIsEndTrialModalOpenReducer} = PaymentsSlice.actions;

export default PaymentsSlice;
