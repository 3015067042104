import {
  UpdateVacancyToCandidateRelationByIds,
  IUpdateRecruitingVacancyToCandidateDto,
} from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { IConnectionAcceptedSlice } from '../connection-list.slice';

export const updateCandidateToVacancy = createAsyncThunk<
  IUpdateRecruitingVacancyToCandidateDto,
  { data: IUpdateRecruitingVacancyToCandidateDto }
>('exmaple/updateVacancyStatus', async ({ data }, { rejectWithValue }) => {
  try {
    await UpdateVacancyToCandidateRelationByIds(data);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateCandidateToVacancyPending = (state: IConnectionAcceptedSlice) => {
  state.vacancyLoading = true;
};
export const updateCandidateToVacancyFullfiled = (
  state: IConnectionAcceptedSlice,
  action: PayloadAction<IUpdateRecruitingVacancyToCandidateDto>,
) => {
  state.candidates = state.candidates.map((c) => {
    if (c.id === action.payload.candidateId) {
      return {
        ...c,
        recruitingVacancyToCandidates: c.recruitingVacancyToCandidates?.map((v) => {
          if (v.recruitingVacancyId === action.payload.recruitingVacancyId)
            return { ...v, candidateStatus: action.payload.candidateStatus };
          return v;
        }),
      };
    }

    return c;
  });

  toast.success('Status has been updated');
  state.vacancyLoading = false;
};
export const updateCandidateToVacancyRejected = (state: IConnectionAcceptedSlice) => {
  toast.error('Status has not been updated');
  state.vacancyLoading = false;
};
