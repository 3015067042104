export const formatNumberUtil = (number: number): string => {
  const numberString = number.toString();

  const formattedNumber = [];
  for (let i = numberString.length; i > 0; i -= 3) {
    formattedNumber.unshift(numberString.substring(Math.max(0, i - 3), i));
  }

  return formattedNumber.join(' ');
};
