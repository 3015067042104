import { ICandidate } from '@EZenkof/common-recruiter-library';
import { createSlice } from '@reduxjs/toolkit';

import {
  filterCandidates,
  filterCandidatesFullfiled,
  filterCandidatesPending,
  filterCandidatesRejected,
} from './reducers/filter-vacancies.reducer';
import {
  changeCandidateStatus,
  changeCandidateStatusFullfiled,
  changeCandidateStatusPending,
  changeCandidateStatusRejected,
} from './reducers/change-status.reducer';
import {
  getNotViewedCountReducer, getNotViewedCountReducerFullfiled,
  getNotViewedCountReducerPending, getNotViewedCountReducerRejected,
} from './reducers/get-new-vacancy-candidates-count.reducer';

export interface IValidateTechnologySlice {
  candidates: ICandidate[];
  count: number;
  notViewedCount: number | null;
  isLoading: boolean;
}

const initialState: IValidateTechnologySlice = {
  candidates: [],
  count: 0,
  notViewedCount: null,
  isLoading: false,
};

const ValidateTechnologySlice = createSlice({
  name: 'validateTechnologySlice',
  initialState: initialState,
  reducers: {
    resetCandidates: (state) => {
      state.candidates = [];
      state.notViewedCount = null;
      state.count = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(filterCandidates.pending, filterCandidatesPending)
      .addCase(filterCandidates.fulfilled, filterCandidatesFullfiled)
      .addCase(filterCandidates.rejected, filterCandidatesRejected)

      .addCase(changeCandidateStatus.pending, changeCandidateStatusPending)
      .addCase(changeCandidateStatus.fulfilled, changeCandidateStatusFullfiled)
      .addCase(changeCandidateStatus.rejected, changeCandidateStatusRejected)

      .addCase(getNotViewedCountReducer.pending, getNotViewedCountReducerPending)
      .addCase(getNotViewedCountReducer.fulfilled, getNotViewedCountReducerFullfiled)
      .addCase(getNotViewedCountReducer.rejected, getNotViewedCountReducerRejected);
  },
});

export const { resetCandidates } = ValidateTechnologySlice.actions;
export default ValidateTechnologySlice;
