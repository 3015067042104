import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import StartTipModal from './support.modal';
import styles from './styles.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
}

const FirstStartTipModal = ({ active, setActive }: Props) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setActive();
  };

  return (
    <StartTipModal active={active} setActive={handleClose}>
      <div className={styles.startTip}>
        <p>
          <Trans i18nKey={t('startModal.header')} components={{ b: <b /> }} />
        </p>
        <p>{t('startModal.tip')}</p>
      </div>
    </StartTipModal>
  );
};

export default FirstStartTipModal;
