import { ICandidate } from '@EZenkof/common-recruiter-library';
import { createSlice } from '@reduxjs/toolkit';

import {
  filterValidateCandidates,
  filterValidateCandidatesFullfiled,
  filterValidateCandidatesPending,
  filterValidateCandidatesRejected,
} from './reducers/filter-vacancies.reducer';
import {
  findMoreCandidates,
  findMoreCandidatesFullfiled,
  findMoreCandidatesPending,
  findMoreCandidatesRejected,
} from './reducers/find-more-vacancies.reducer';

export interface IValidateTechnologyConfirmSlice {
  candidates: ICandidate[];
  count: number;
  isLoading: boolean;
  findMoreLoading: boolean;
}

const initialState: IValidateTechnologyConfirmSlice = {
  candidates: [],
  count: 0,
  isLoading: false,
  findMoreLoading: false,
};

const ValidateTechnologyConfirmSlice = createSlice({
  name: 'validateTechnologyConfirmSlice',
  initialState: initialState,
  reducers: {
    resetCandidates: (state) => {
      state.candidates = [];
      state.count = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(filterValidateCandidates.pending, filterValidateCandidatesPending)
      .addCase(filterValidateCandidates.fulfilled, filterValidateCandidatesFullfiled)
      .addCase(filterValidateCandidates.rejected, filterValidateCandidatesRejected)

      .addCase(findMoreCandidates.pending, findMoreCandidatesPending)
      .addCase(findMoreCandidates.fulfilled, findMoreCandidatesFullfiled)
      .addCase(findMoreCandidates.rejected, findMoreCandidatesRejected);
  },
});

export const { resetCandidates } = ValidateTechnologyConfirmSlice.actions;
export default ValidateTechnologyConfirmSlice;
