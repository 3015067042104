import { GetIsTrialEnded } from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import { IPaymentsSlice } from '../payments.slice';

interface IGetResult {
  isEnded: boolean | null,
}

export const getIsTrialEndedReducer = createAsyncThunk<IGetResult, {}>(
  'paymentsSlice/getIsTrialEnded',
  async ({}, {rejectWithValue}) => {
    try {
      const res = await GetIsTrialEnded();
      
      return {isEnded: res};
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getIsTrialEndedReducerPending = (state: IPaymentsSlice) => {};
export const getIsTrialEndedReducerFullfiled = (state: IPaymentsSlice, action: PayloadAction<IGetResult>) => {
  if (action.payload.isEnded === null) {
    state.isEndTrialModalOpen = true;

    toast.error('Cannot get trial status');
  }else state.isEndTrialModalOpen = action.payload.isEnded;
};
export const getIsTrialEndedReducerRejected = (state: IPaymentsSlice) => {};
