import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useLocalStorage } from '../../common/helper/hooks/use-local-storage.hook';
import { getPaymentsInfoReducer } from '../../store/slices/payments/reducers/get-payments-info.reducer';
import { millisecondsInDay } from '../../common/helper/consts/dates-milliseconds';
import { setFeedbackFrameType } from '../../store/slices/feedback-frames/feedback-frames.slice';
import { useAppDispatch, useAppSelector } from '../../store/interface/store.interface';

const CollectStatisticLayer = () => {
  const [sessionsStatistic, setSessionsStatistic] = useLocalStorage<
    { lastSessionDate: number, session: number, isCollectedInitialFeedback?: boolean, isCollectedFinalFeedback?: boolean}
  >(
    'sessionsStatistic',
    {lastSessionDate: 0, session: 0, isCollectedInitialFeedback: false, isCollectedFinalFeedback: false},
  );

  //const location = useLocation();

  const {info} = useAppSelector((state) => state.payments);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPaymentsInfoReducer({}));
  }, []);
  
  useEffect(() => {
    if (!sessionsStatistic?.lastSessionDate)
      setSessionsStatistic({ lastSessionDate: Date.now(), session: 1 });
    else if (info.trialEndDate) {
      if (
        Number(info.trialEndDate) <= Date.now() + millisecondsInDay * 5 &&
        sessionsStatistic.isCollectedInitialFeedback &&
        !sessionsStatistic.isCollectedFinalFeedback
      ) {
        dispatch(setFeedbackFrameType({ activeType: 'finalFeedback' }));
        setSessionsStatistic({
          ...sessionsStatistic,
          isCollectedFinalFeedback: true,
          session: sessionsStatistic.session + 1,
        });
      } else if (sessionsStatistic.session === 3) {
        dispatch(setFeedbackFrameType({ activeType: 'initialFeedback' }));
        setSessionsStatistic({
          ...sessionsStatistic,
          lastSessionDate: Date.now(),
          isCollectedInitialFeedback: true,
          session: sessionsStatistic.session + 1,
        });
      } else if (sessionsStatistic.lastSessionDate + millisecondsInDay <= Date.now())
        setSessionsStatistic({
          ...sessionsStatistic,
          lastSessionDate: Date.now(),
          session: sessionsStatistic.session + 1,
        });
    }
  }, [info.trialEndDate]);

  {/*todo: на время убираем*/}
  {/*todo - зарефакторить через getPaymentsInfo ?*/}
  // useEffect(() => {
  //   if (user && !location.pathname.includes('payments'))
  //     dispatch(getIsTrialEndedReducer({}));
  // }, [location.pathname]);
  
  return <></>;
};

export default CollectStatisticLayer;
