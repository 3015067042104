import { GetCandidates, ICandidate, IFilterCandidatesDto } from '@EZenkof/common-recruiter-library';
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IValidateTechnologyConfirmSlice } from '../vatidate-confirm.slice';

interface IFilter {
  candidates: ICandidate[];
}

export const findMoreCandidates = createAsyncThunk<IFilter, { data: IFilterCandidatesDto }>(
  'validateTechnologyConfirmSlice/findMoreCandidates',
  async ({ data }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as { validateTechnologyConfirmSlice: IValidateTechnologyConfirmSlice };
      const res = await GetCandidates({...data, skip: state.validateTechnologyConfirmSlice.candidates.length});
      return { candidates: res || [] };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const findMoreCandidatesPending = (state: IValidateTechnologyConfirmSlice) => {
  state.findMoreLoading = true;
};
export const findMoreCandidatesFullfiled = (state: IValidateTechnologyConfirmSlice, action: PayloadAction<IFilter>) => {
  state.candidates = [...state.candidates, ...action.payload.candidates];
  state.findMoreLoading = false;
};
export const findMoreCandidatesRejected = (state: IValidateTechnologyConfirmSlice) => {
  state.findMoreLoading = false;
};
