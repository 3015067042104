import { createSlice } from '@reduxjs/toolkit';

export interface IParsePlansSlice {}

const initialState: IParsePlansSlice = {};

const ParsePlansSlice = createSlice({
  name: 'parsePlans',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {},
});

export default ParsePlansSlice;
