import { createSlice } from '@reduxjs/toolkit';
import { IRecruitingVacancy } from '@EZenkof/common-recruiter-library';

import {
  getVacanciesReducer,
  getVacanciesReducerFullfiled,
  getVacanciesReducerPending,
  getVacanciesReducerRejected,
} from './reducers/get-all-vacancies.reducer';
import {
  updateVacancyReducer,
  updateVacancyReducerFullfiled,
  updateVacancyReducerPending,
  updateVacancyReducerRejected,
} from './reducers/update-vacancy.reducer';
import {
  createVacancyReducer,
  createVacancyReducerFullfiled,
  createVacancyReducerPending,
  createVacancyReducerRejected,
} from './reducers/add-vacancy.reducer';
import {
  deleteVacancyReducer,
  deleteVacancyReducerFullfiled,
  deleteVacancyReducerPending,
  deleteVacancyReducerRejected,
} from './reducers/delete-vacancies.reducer';
import {
  filterVacanciesReducer,
  filterVacanciesReducerFullfiled,
  filterVacanciesReducerPending,
  filterVacanciesReducerRejected,
} from './reducers/filter-vacancies.reducer';
import {
  getVacancyByIdReducer,
  getVacancyByIdReducerFullfiled,
  getVacancyByIdReducerPending, getVacancyByIdReducerRejected,
} from './reducers/get-vacancy-by-id.reducer';

export interface IVacanciesSlice {
  recentVacancy: IRecruitingVacancy | null;
  vacancies: IRecruitingVacancy[];
  count: number;
  isLoading: boolean;
}

const initialState: IVacanciesSlice = {
  recentVacancy: null,
  vacancies: [],
  count: 0,
  isLoading: false,
};

const VacanciesSlice = createSlice({
  name: 'vacancies',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createVacancyReducer.pending, createVacancyReducerPending)
      .addCase(createVacancyReducer.fulfilled, createVacancyReducerFullfiled)
      .addCase(createVacancyReducer.rejected, createVacancyReducerRejected)

      .addCase(filterVacanciesReducer.pending, filterVacanciesReducerPending)
      .addCase(filterVacanciesReducer.fulfilled, filterVacanciesReducerFullfiled)
      .addCase(filterVacanciesReducer.rejected, filterVacanciesReducerRejected)

      .addCase(deleteVacancyReducer.pending, deleteVacancyReducerPending)
      .addCase(deleteVacancyReducer.fulfilled, deleteVacancyReducerFullfiled)
      .addCase(deleteVacancyReducer.rejected, deleteVacancyReducerRejected)

      .addCase(getVacanciesReducer.pending, getVacanciesReducerPending)
      .addCase(getVacanciesReducer.fulfilled, getVacanciesReducerFullfiled)
      .addCase(getVacanciesReducer.rejected, getVacanciesReducerRejected)

      .addCase(updateVacancyReducer.pending, updateVacancyReducerPending)
      .addCase(updateVacancyReducer.fulfilled, updateVacancyReducerFullfiled)
      .addCase(updateVacancyReducer.rejected, updateVacancyReducerRejected)

      .addCase(getVacancyByIdReducer.pending, getVacancyByIdReducerPending)
      .addCase(getVacancyByIdReducer.fulfilled, getVacancyByIdReducerFullfiled)
      .addCase(getVacancyByIdReducer.rejected, getVacancyByIdReducerRejected);
  },
});

export default VacanciesSlice;
