import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllLinkedInUsers, ILinkedInUser } from '@EZenkof/common-recruiter-library';

import { IUsersSlice } from '../users.slice';

interface IResponse {
  count: number;
  users: ILinkedInUser[];
}

export const getAllLinkedinUsersReducer = createAsyncThunk<IResponse>(
  'users/getAllLinkedInUsers',
  async (_, { rejectWithValue }) => {
    try {
      const data = await GetAllLinkedInUsers();
      if (!data) throw new Error('Произошла ошибка. Попробуйте снова');

      return { users: data, count: data.length };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getAllLinkedinUsersReducerPending = (state: IUsersSlice) => {
  state.linkedIn.isLoading = true;
};
export const getAllLinkedinUsersReducerFullfiled = (state: IUsersSlice, action: PayloadAction<IResponse>) => {
  state.linkedIn = { ...action.payload, isLoading: false };
};
export const getAllLinkedinUsersReducerRejected = (state: IUsersSlice) => {
  state.linkedIn.isLoading = false;
};
