import { createSlice } from '@reduxjs/toolkit';

import {
  initFirstStartTipFullfiled,
  initFirstStartTipReducer,
  setFirstStartTipReducer,
  setFirstStartTipReducerFullfiled,
  setStartTipReducer,
  setStartTipReducerFullfiled,
} from './reducers/set-start-tip.reducer';

export interface ITipsSlice {
  startTip: boolean;
  firstStartTip: boolean;
}

const initialState: ITipsSlice = {
  startTip: false,
  firstStartTip: false,
};

const TipsSlice = createSlice({
  name: 'tips',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initFirstStartTipReducer.fulfilled, initFirstStartTipFullfiled);
    builder.addCase(setFirstStartTipReducer.fulfilled, setFirstStartTipReducerFullfiled);
    builder.addCase(setStartTipReducer.fulfilled, setStartTipReducerFullfiled);
  },
});

export default TipsSlice;
