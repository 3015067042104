import React, { useRef, useState } from 'react';
import InfoSvg from 'common/svg/info.svg';
import { useAppDispatch } from 'store/interface/store.interface';
import { setStartTipReducer } from 'store/slices/tips/reducers/set-start-tip.reducer';
import { useLocation } from 'react-router-dom';
import useClickOutside from 'common/helper/hooks/use-click-outside.hook';

import QuestionSvg from '../../svg/question.svg';
import LampSvg from '../../svg/lamp.svg';

import TelegramSvg from '../../svg/telegram.svg';

import BugSvg from '../../svg/bug.svg';

import { setFeedbackFrameType } from '../../../store/slices/feedback-frames/feedback-frames.slice';

import styles from './styles.module.scss';

const Tip = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = React.useState(location.pathname);
  const [active, setActive] = useState<boolean>(false);
  
  const [isHelpClicked, setIsHelpClicked] = useState<boolean>(false);

  const handleActive = (): void => {
    setActive((s) => !s);
    setIsHelpClicked(false);
  };

  const ref = useRef(null);
  useClickOutside(ref, handleActive, active);

  React.useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const dispatch = useAppDispatch();
  const handleStartTip = () => {
    dispatch(setStartTipReducer());
  };
  
  const handleHelpClick = () => setIsHelpClicked((prev) => !prev);

  const handleOpenBugReportFrame = () => {
    handleActive();
    dispatch(setFeedbackFrameType({activeType: 'bugReport'}));
  };

  const handleOpenSuggestionFrame = () => dispatch(setFeedbackFrameType({activeType: 'suggestion'}));
  
  if (!currentPath.includes('profile')) {
    return <></>;
  }

  return (
    <>
      {active && (
        <div className={styles.modal} ref={ref}>
          <button className={styles.link} onClick={handleHelpClick}>
            <QuestionSvg />
            <p>Hyranse Help</p>
          </button>
          {isHelpClicked && <div className={styles.additional}>
            <a className={styles.link} href="https://t.me/hyranse_support" target="_blank" rel="noreferrer">
              <TelegramSvg />
              <p>Message</p>
            </a>
            <button className={styles.link} onClick={handleOpenBugReportFrame}>
              <BugSvg />
              <p>Bug report</p>
            </button>
          </div>}
          <button className={styles.link} onClick={handleStartTip}>
            <InfoSvg />
            <p>Onboarding</p>
          </button>
          <button className={styles.link} onClick={handleOpenSuggestionFrame}>
            <LampSvg />
            <p>Suggest Improvement</p>
          </button>
        </div>
      )}
      <button onClick={handleActive} className={styles.container}>
        ?
      </button>
    </>
  );
};

export default Tip;
