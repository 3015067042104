import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ITipsSlice } from '../tips.slice';

export const initFirstStartTipReducer = createAsyncThunk<boolean, void>('tips/initFirstStartTip', () => {
  const init = localStorage.getItem('startTipWatched');
  return init !== 'true';
});

export const initFirstStartTipFullfiled = (state: ITipsSlice, action: PayloadAction<boolean>) => {
  state.firstStartTip = action.payload;
};

export const setFirstStartTipReducer = createAsyncThunk<boolean, boolean>('tips/setFirstStartTip', (data) => {
  localStorage.setItem('startTipWatched', 'true');
  return data;
});

export const setFirstStartTipReducerFullfiled = (state: ITipsSlice, action: PayloadAction<boolean>) => {
  state.firstStartTip = action.payload;
};

export const setStartTipReducer = createAsyncThunk<boolean, boolean | undefined>(
  'tips/setStartTip',
  (data, { getState }) => {
    const state = getState() as { tips: ITipsSlice };
    return typeof data === 'boolean' ? data : !state.tips.startTip;
  },
);

export const setStartTipReducerFullfiled = (state: ITipsSlice, action: PayloadAction<boolean>) => {
  state.startTip = action.payload;
};
