import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FeedbackType = 'bugReport' | 'suggestion' | 'initialFeedback' | 'finalFeedback' | '';

export interface IFeedbackFramesSlice {
  activeType: FeedbackType,
}

const initialState: IFeedbackFramesSlice = {
  activeType: '',
};

const FeedbackFramesSlice = createSlice({
  name: 'feedbackFramesSlice',
  initialState: initialState,
  reducers: {
    setFeedbackFrameType(state, action: PayloadAction<IFeedbackFramesSlice>){
      state.activeType = action.payload.activeType;
    },
  },
});

export const {setFeedbackFrameType} = FeedbackFramesSlice.actions;

export default FeedbackFramesSlice;
