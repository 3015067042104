import { UpdateVacancyById, IUpdateRecruitingVacancyDto } from '@EZenkof/common-recruiter-library';
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { IVacanciesSlice } from '../vacancies.slice';

interface IUpdate {
  vacancy: IUpdateRecruitingVacancyDto;
  id: number;
}

export const updateVacancyReducer = createAsyncThunk<IUpdate, IUpdate>(
  'vacancies/updateVacancy',
  async ({ id, vacancy }, { rejectWithValue }) => {
    try {
      await UpdateVacancyById(id, vacancy);
      return { vacancy: vacancy, id: id };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateVacancyReducerPending = (state: IVacanciesSlice) => {
  state.isLoading = true;
};
export const updateVacancyReducerFullfiled = (state: IVacanciesSlice, action: PayloadAction<IUpdate>) => {
  state.vacancies = state.vacancies.map((v) => {
    if (v.id === action.payload.id) return { ...v, ...action.payload.vacancy };
    return v;
  });
  
  //toast.success('Vacancy has been updated');
  state.isLoading = false;
};
export const updateVacancyReducerRejected = (state: IVacanciesSlice) => {
  //toast.error('Vacancy has not been updated');
  state.isLoading = false;
};
